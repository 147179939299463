exports.components = {
  "component---src-components-pages-categories-media-category-jsx": () => import("./../../../src/components/Pages/Categories/MediaCategory.jsx" /* webpackChunkName: "component---src-components-pages-categories-media-category-jsx" */),
  "component---src-components-pages-categories-news-full-jsx": () => import("./../../../src/components/Pages/Categories/NewsFull.jsx" /* webpackChunkName: "component---src-components-pages-categories-news-full-jsx" */),
  "component---src-components-pages-categories-news-jsx": () => import("./../../../src/components/Pages/Categories/News.jsx" /* webpackChunkName: "component---src-components-pages-categories-news-jsx" */),
  "component---src-components-pages-page-page-jsx": () => import("./../../../src/components/Pages/Page/Page.jsx" /* webpackChunkName: "component---src-components-pages-page-page-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-policy-briefs-js": () => import("./../../../src/pages/policy-briefs.js" /* webpackChunkName: "component---src-pages-policy-briefs-js" */),
  "component---src-pages-project-workshops-js": () => import("./../../../src/pages/project-workshops.js" /* webpackChunkName: "component---src-pages-project-workshops-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

